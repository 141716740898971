import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { Button } from '../components/button';

function Login() {

    const { login } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const googleSuccess = async (res : any) => {
        const userInfo = jwtDecode(res.credential);
        console.log(userInfo);

        try {
            const response = await fetch(`${process.env.REACT_APP_PUBLIC_HOST}/api/auth/google`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userInfo),
            });

            if (response.ok) {
                const res = await response.json();
                const token = res.token;
                const user = res.user;

                console.log('Login successful with Google');
                setError(null);
                login(token, user);
                navigate('/editor');
            } 
            else {
                response.json().then(data => {
                    setError(data.msg);
                }).catch(error => {
                    setError('An unexpected error occurred.');
                });
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    }

    const googleFailure = () => {
        console.log(error);
    }

    const handleSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const registrationData = {
            email,
            password,
        };

        try {
            // Send the registration data to your server for processing
            const response = await fetch(`${process.env.REACT_APP_PUBLIC_HOST}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(registrationData),
            });

            if (response.ok) {
                // Registration was successful, you can handle the success scenario here
                const res = await response.json();
                const token = res.token;
                const user = res.user;

                console.log('Login successful');
                setError(null);
                login(token, user);
                navigate('/editor');
            } 
            else {
                response.json().then(data => {
                    setError(data.msg);
                }).catch(error => {
                    setError('An unexpected error occurred.');
                });
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-10 w-auto"
                    src="logo.png"
                    alt="Your Company"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-black">
                    Sign in to your account
                </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-black">
                        Password
                        </label>
                        <div className="text-sm">
                        {/* <Link to="/" className="font-semibold text-indigo-600 hover:text-indigo-500">
                            Forgot password?
                        </Link> */}
                        </div>
                    </div>
                    <div className="mt-2">
                        <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div>
                    <Button 
                        type="submit"
                        color="dark"
                        className="w-full"
                    >
                        Sign in
                    </Button>
                    <div className="mt-5 text-red-500 text-sm">
                        {error}
                    </div>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member?{' '}
                    <Link to="/register" className="font-semibold leading-6 text-black">
                        Sign up now
                    </Link>
                </p>
                <div className="mt-10 text-center text-sm text-gray-500">
                    <div className="flex justify-center">
                        <GoogleLogin onSuccess={googleSuccess} onError={googleFailure} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Login };


  