import { Button } from '../components/button'
import { Divider } from '../components/divider'
import { Heading, Subheading } from '../components/heading'
import { Input } from '../components/input'
import { Select } from '../components/select'
import { Text } from '../components/text'
import { Textarea } from '../components/textarea'
import type { Metadata } from 'next'
import { useState } from 'react'
import { fetchWithAuth } from '../utils/fetchWithAuth'  
import { useEffect } from 'react'
import { User, Token } from '../utils/types'
import { HiX } from "react-icons/hi";

export const metadata: Metadata = {
  title: 'Settings',
}

interface SettingsProps {
  user: User;
  token: Token;
}

export default function Settings({ user, token }: SettingsProps) {

  const [targetSchools, setTargetSchools] = useState(['', '', '']);
  const [desiredProfile, setDesiredProfile] = useState('');
  const [intendedMajor, setIntendedMajor] = useState('');
  const [essayType, setEssayType] = useState('STANDARD');
  const [activities, setActivities] = useState(['']);
  const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (!user) {
            console.error('User is not authenticated');
            return;
        }
        
        const fetchProfile = async () => {
            try {
                const data = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/user/profile?userId=${user._id}`, token, {
                    method: 'GET',
                });
                const fetchedTargetSchools = data.user.targetSchools || ['', '', ''];
                setTargetSchools([
                    fetchedTargetSchools[0] || '',
                    fetchedTargetSchools[1] || '',
                    fetchedTargetSchools[2] || ''
                ]);
                setDesiredProfile(data.user.desiredProfile || '');
                setIntendedMajor(data.user.intendedMajor || '');
                setEssayType(data.user.essayType || 'STANDARD');
                setActivities(data.user.activities.length > 0 ? data.user.activities : ['']);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    }, [user, token]);

  const handleAddActivity = () => {
    setActivities([...activities, '']);
  };

  const handleActivityChange = (index: number, value: string) => {
    const newActivities = [...activities];
    newActivities[index] = value;
    setActivities(newActivities);
  };

  const handleRemoveActivity = (index: number) => {
    setActivities(activities.filter((_, i) => i !== index));
  };

  const handleTargetSchoolChange = (index: number, value: string) => {
    const newTargetSchools = [...targetSchools];
    newTargetSchools[index] = value;
    setTargetSchools(newTargetSchools);
  };

  const handleDesiredProfileChange = (value: string) => {
    setDesiredProfile(value);
  };

  const handleIntendedMajorChange = (value: string) => {
    setIntendedMajor(value);
  };

  const handleEssayTypeChange = (value: string) => {
    setEssayType(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!user) {
        console.error('User is not authenticated');
        return;
    }

    setIsSaving(true); // Set isSaving to true when submission starts

    // Clean the activities, remove empty strings
    const cleanedActivities = activities.filter(activity => activity.trim() !== '');
    
    // Make sure essayType is either STANDARD or CREATIVE
    const validEssayTypes = ['STANDARD', 'CREATIVE'];
    if (!validEssayTypes.includes(essayType)) {
        console.error('Invalid essay type');
        return;
    }

    // Clean targetSchools, remove empty strings or strings with only whitespace
    const cleanedTargetSchools = targetSchools.filter(school => school.trim() !== '');

    // Clean desiredProfile, remove empty strings or strings with only whitespace
    const cleanedDesiredProfile = desiredProfile.trim() !== '' ? desiredProfile : '';

    // Clean intendedMajor, remove empty strings or strings with only whitespace
    const cleanedIntendedMajor = intendedMajor.trim() !== '' ? intendedMajor : '';

    const data = {
        userId: user._id,
        targetSchools: cleanedTargetSchools,
        desiredProfile: cleanedDesiredProfile,
        intendedMajor: cleanedIntendedMajor,
        essayType: essayType,
        activities: cleanedActivities
    };

    console.log(data);

    try {
        await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/user/profile`, token, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        console.log('Profile updated successfully');
    } catch (error) {
        console.error('Error saving profile:', error);
    } finally {
        setIsSaving(false); // Reset isSaving to false after submission completes
    }
};

  return (
    <form method="post" className="mx-auto max-w-4xl" onSubmit={handleSubmit}>
      <Heading>Settings</Heading>
      <Divider className="my-10 mt-6" />

      <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="space-y-1">
          <Subheading>Intended Major</Subheading>
          <Text>Your intended major to pursue.</Text>
        </div>
        <div>
          <Input
            aria-label="Intended Major"
            name="major"
            value={intendedMajor}
            onChange={(e) => handleIntendedMajorChange(e.target.value)}
          />
        </div>
      </section>

      <Divider className="my-10" soft />

      <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="space-y-1">
          <Subheading>Top Schools</Subheading>
          <Text>Top 3 schools you want to get into.</Text>
        </div>
        <div>
          <Input
            aria-label="First Choice"
            name="school"
            value={targetSchools[0]}
            onChange={(e) => handleTargetSchoolChange(0, e.target.value)}
            className="mb-2"
          />
          <Input
            aria-label="Second Choice"
            name="school"
            value={targetSchools[1]}
            onChange={(e) => handleTargetSchoolChange(1, e.target.value)}
            className="mb-2"
          />
          <Input
            aria-label="Third Choice"
            name="school"
            value={targetSchools[2]}
            onChange={(e) => handleTargetSchoolChange(2, e.target.value)}
          />
        </div>
      </section>

      <Divider className="my-10" soft />

      <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="space-y-1">
          <Subheading>Desired Profile</Subheading>
          <Text>A one sentence description of how you want to be presented.</Text>
        </div>
        <div>
          <Textarea
            aria-label="Desired Profile"
            name="profile"
            value={desiredProfile}
            onChange={(e) => handleDesiredProfileChange(e.target.value)}
          />
        </div>
      </section>

      <Divider className="my-10" soft />

      <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="space-y-1">
          <Subheading>Essay Type</Subheading>
          <Text>Do you prefer a standard essay or a more creative one?</Text>
        </div>
        <div>
          <Select
            aria-label="EssayType"
            name="type"
            value={essayType}
            onChange={(e) => handleEssayTypeChange(e.target.value)}
          >
            <option value="STANDARD">Standard</option>
            <option value="CREATIVE">Creative</option>
          </Select>
        </div>
      </section>

      <Divider className="my-10" soft />

      <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="space-y-1">
          <Subheading>Activities</Subheading>
          <Text>List your extracurricular activities.</Text>
        </div>
        <div>
          {activities.map((activity, index) => (
            <div key={index} className="relative mb-2 pr-10">
              <Input
                aria-label={`Activity ${index + 1}`}
                name={`activity-${index}`}
                value={activity}
                onChange={(e) => handleActivityChange(index, e.target.value)}
              />
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={() => handleRemoveActivity(index)}
              >
                <HiX className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          ))}
          <Button type="button" onClick={handleAddActivity} color="dark">
            Add Activity
          </Button>
        </div>
      </section>

      <Divider className="my-10" soft />

      <div className="flex justify-end gap-4">
        <Button type="reset" plain>
          Reset
        </Button>
        <Button type="submit" color="dark" disabled={isSaving}>
          {isSaving ? 'Saved!' : 'Save changes'}
        </Button>
      </div>
    </form>
  )
}
